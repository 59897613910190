<template>
  <qrcode-stream
    :camera="camera"
    style="width: 76vw; height:76vw"
    @decode="onDecode"
    @init="onInit"
  >
    <div
      v-if="pending || loading"
      class="pending"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </qrcode-stream>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import { QrcodeStream } from 'vue-qrcode-reader'
import { mdiLoading } from '@mdi/js'

export default {
  components: {
    QrcodeStream,
  },
  props: {
    value: { type: String, default: '' },
  },
  setup(props, { emit }) {
    // Data ref
    const camera = ref('auto')
    const result = useVModel(props, 'value', emit, { eventName: 'input' })
    const loading = ref(true)

    // Computed
    const pending = computed(() => camera.value === 'off')

    const turnCameraOn = () => {
      camera.value = 'auto'
    }

    const turnCameraOff = () => {
      camera.value = 'off'
    }

    const timeout = ms =>
      // eslint-disable-next-line implicit-arrow-linebreak
      new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })

    const onDecode = async content => {
      result.value = content
      turnCameraOff()

      // some delay, so users have time to read the message
      await timeout(2000)

      turnCameraOn()
    }

    const onInit = async () => {
      await timeout(1000)
      loading.value = false
    }

    return {
      camera,
      pending,
      loading,
      onDecode,
      onInit,
      icons: {
        mdiLoading,
      },
    }
  },
}
</script>

<style  lang="scss" scoped>
.pending {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
</style>
